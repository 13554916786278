.btn.btn-quad {
  padding: 2px;
  border-radius: 3px;
  fill: var(--bs-light);
  transition: fill 0.15s ease-in-out;
}

.btn.btn-quad:hover {
  fill: var(--bs-dark);
  background-color: var(--bs-light);
}

.btn.btn-quad svg {
  width: 30px;
  height: 30px;
}

.btn.btn-quad.rot-45 {
  transform: rotate(45deg);
}

.circle-wrapper {
  width: 175px;
  height: 175px;
  position: relative;
}

.circle {
  display: block;
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
}

.deg-0 {
  transform: translate(65px);
}

.deg-45 {
  transform: rotate(46deg) translate(65px);
}

.deg-90 {
  transform: rotate(91deg) translate(65px);
}

.deg-135 {
  transform: rotate(136deg) translate(65px);
}

.deg-180 {
  transform: rotate(181deg) translate(65px);
}

.deg-225 {
  transform: rotate(226deg) translate(65px);
}

.deg-270 {
  transform: rotate(271deg) translate(65px);
}

.deg-315 {
  transform: rotate(316deg) translate(65px);
}
