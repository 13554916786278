.notification-container {
  width: 50%;
  position: fixed;
  top: 20px;
  left: 25%;
  z-index: 3000;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 400px) {
  .notification-container {
    width: 95%;
    left: calc(5% / 2);
  }
}
