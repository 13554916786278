.dark .nav-tabs .nav-link {
  font-weight: bold;
}

.dark .nav-tabs .nav-link.active {
  color: var(--bs-light);
  background-color: #444;
  border-color: #dee2e6 #dee2e6 transparent;
}

.dark .nav-link:focus,
.dark .nav-link:hover {
  color: var(--bs-primary);
}

.btn-quad-lg {
  width: 100px;
  height: 100px;
  padding: 3px !important;
}
