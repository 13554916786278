#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--bs-dark);
  color: var(--bs-light);
}

main {
  flex: 1 1 auto;
  overflow: auto;
}

.dark .btn-close {
  color: var(--bs-light);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e");
}

.dark .form-control {
  background-color: #333;
  color: var(--bs-light);
}

.dark .form-control:focus {
  background-color: #444;
  color: var(--bs-light);
}

.btn-outline-light svg {
  fill: var(--bs-light);
}

.btn-outline-light:hover svg {
  fill: var(--bs-dark);
}
